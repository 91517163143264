import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import { LoadingController, IonFooter } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
// import * as CryptoJS from 'crypto-js';
declare var require: any

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  private endpoint = environment.endpoint;
  private url = this.endpoint;

  loading: any = new LoadingController;
  driverData: any;
  require: any 


  constructor(
    private httpclient: HttpClient,
    public loadingCtrl: LoadingController,
    private storage: Storage,

  ) { }

  private getHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + token
      })
    };
  }

  async presentLoading(msg) {
    this.loading = await this.loadingCtrl.create({
      message: msg,
      spinner: 'crescent',
      cssClass: 'custom-class'
    });
    return await this.loading.present();
  }

  async accountsDetails() {
    return new Promise(resolve => {
      this.storage.get('ACCOUNTS_TABLE').then(res => {
        //////console.log(res);
        this.driverData = res
        resolve(res)
      })
    }).catch(err => {
      //////console.log(err)
    })
  }

  getCustomer(info) {

    let params: any = {
    //  email : "it01.azaza@gmail.com",
    //  password : "7c222fb2927d828af22f592134e8932480637c0d",
    //  userid : "109",
    //  drivername : "dummydriver",
     userid : this.driverData.id,
     email : this.driverData.email_address,
     password : this.driverData.password,
     drivername : this.driverData.name,
     contactno : info.contactno,
     postal : info.postal
    }
  
    return new Promise(resolve => {
      this.httpclient.post(this.url + "/customer.json", params).subscribe(
        response => {
          let res;
          res = response;
          //////console.log(res)
          this.storage.set('SELECTED_CUSTOMER', res[0]).then(() => {

          });
          resolve(res)
        },
        err => {
          //////console.log(err)
          resolve(false)

          // alert(JSON.stringify(err));
        }
      );

    }).catch(err => {
      //////console.log(err)
    })
  }


  addCustomerStandingOrder(info) {
    //////console.log(info)
    // this.storage.get('ACCOUNTS_TABLE').then(res => {
    //   //////console.log(res);
    //   this.driverData = res
    // })

    let params: any = {}
      // params.email = "it01.azaza@gmail.com"
      // params.password = "7c222fb2927d828af22f592134e8932480637c0d"
      // params.userid = "109",
      // params.drivername = "dummydriver",
      params.userid = this.driverData.id
      params.email = this.driverData.email_address
      params.password = this.driverData.password
      params.drivername = this.driverData.name
      // params.companyagent = info.companyagent,
      params.contactperson1 = info.contactperson1
      params.contactno1 = info.contactno1
      params.mailingaddress = info.mailingaddress
      params.unitno = info.unitno,
      params.builidngname = info.builidngname
      params.postalcode = info.postalcode
      params.liftlobby = info.liftlobby
      params.areacode = info.areacode
      params.contactno2 = info.contactno2
      params.emailcus = info.emailcus
      params.contactperson2 = info.contactperson2
      params.customertype = info.customertype



    //////console.log(params)
    
    return new Promise(resolve => {
      this.httpclient.post(this.url + "/addcustomerdata.json", params).subscribe(
        response => {
          let returndata = response
          params.customerid = returndata
          let newdata: any [] = params
          resolve(returndata)
        },
        err => {
          //////console.log(err)
          resolve(err)

          // alert(JSON.stringify(err));
        }
      );

    }).catch(err => {
      //////console.log(err)
    })
  }

  login(info: any) {
    let infoi
    // if(driver == "LA"){
    // infoi = {
    //   "email": "lalamovea@cottoncare.com.sg",
    //   "password": "lalamovea",
    // }
    // }else if(driver == "LB"){
    // infoi = {
    //   "email": "lalamoveb@cottoncare.com.sg",
    //   "password": "lalamoveb",
    // }
    // }else if(driver == "LC"){
    // infoi = {
    //   "email": "lalamovec123@cottoncare.com.sg",
    //   "password": "lalamovec",
    // }
    // }

    // infoi = {
    //   "email": "it01.azaza@gmail.com",
    //   "password": "12345678",
    // }

    infoi = {
      "email": "del@cottoncare.com.sg",
      "password": "delivery123",
    }

    this.storage.set('REMEMBER_ME', infoi).then(() => {});
    var sha1 = require('sha1');
    
    infoi.password = sha1(infoi.password);

    if (navigator.onLine == true) {
      return new Promise(resolve => {
        this.httpclient.post(this.url + "/logon.json", infoi).subscribe(
          response => {
            let res;
            res = response[0];
            // //////console.log(res)
            this.driverData = res
            this.storage.set('ACCOUNTS_TABLE', res).then(() => {
              // this.getStandingOrder(res)
                // this.getStandingOrder(res)
                resolve(res)
            });
  
          },
          err => {
            //////console.log(err)
            resolve(false)
  
            // alert(JSON.stringify(err));
          }
        );
      }).catch(err => {
        //////console.log(err)
        return new Promise(resolve => {
          this.storage.get('ACCOUNTS_TABLE').then(res => {
            //////console.log(res);
            this.driverData = res
            if(res.email_address == info.email && res.password == info.password){
              resolve(res)
            }else{
  
            }
          })
        }).catch(err => {
          //////console.log(err)
        })
      })
    }else{
      return new Promise(resolve => {
        this.storage.get('ACCOUNTS_TABLE').then(res => {
          //////console.log(res);
          this.driverData = res
          if(res.email_address == info.email && res.password == info.password){
            resolve(res)
          }else{

          }
        })
      }).catch(err => {
        //////console.log(err)
      })
    }



  }

  getStandingOrder(info) {
    let params = {
      userid: info.id,
      email: info.email_address,
      password: info.password
    }

    // let params = {
    //   "email": "it01.azaza@gmail.com",
    //   "password": "7c222fb2927d828af22f592134e8932480637c0d",
    //   "userid": "109",
    //  }

    return new Promise(resolve => {
      this.httpclient.post(this.url + "/standingorder.json", params).subscribe(
        response => {
          let res;
          res = response[0];
          // //////console.log(res)
          this.storage.set('SO_TABLE', res[0]).then(() => {
            resolve(res)
          });
        },
        err => {
          //////console.log(err)
          resolve(false)

          // alert(JSON.stringify(err));
        }
      );

    }).catch(err => {
      //////console.log(err)
    })
  }

  getOverDue(info) {
    let params = {
      userid: info.id,
      email: info.email_address,
      password: info.password
    }

    // let params = {
    //   "email": "it01.azaza@gmail.com",
    //   "password": "7c222fb2927d828af22f592134e8932480637c0d",
    //   "userid": "109",
    //  }

    return new Promise(resolve => {
      this.httpclient.post(this.url + "/gzipoverdue.json", params).subscribe(
        response => {
          let res;
          res = response[0];
          //console.log(res)
          this.storage.set('overdue_table', res[0]).then(() => {
            resolve(res)
          });
        },
        err => {
          //////console.log(err)
          resolve(false)

          // alert(JSON.stringify(err));
        }
      );

    }).catch(err => {
      //////console.log(err)
    })
  }

}
